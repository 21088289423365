export const findPriceAndType = (inputString) => {

    // Remove crossed-out text
    inputString = inputString.replace(/\[-](.*?)[/-]/g, '');
    const priceTypeRegex = "SB|BIN|FP|Starting Bid|SR";

    // Regex for structured prices
    const structuredRegex = new RegExp(`(?:${priceTypeRegex}):?.*?(?:\\$?)(\\d+(?:\\.\\d{1,2})?)`, 'g');
    // Regex for unstructured prices, excluding adjacent text
    const unstructuredRegex = /(?<![a-zA-Z])\$?\d+(?:\.\d{1,2})?(?![a-zA-Z])/g;

    const prices = [];
    let match;

    // Extract structured prices
    while ((match = structuredRegex.exec(inputString)) !== null) {
        const validPrice = parseFloat(match[1]);
        if (validPrice < 1000) { // Ignore prices greater than 999
            prices.push({
                price: validPrice,
                type: match[0].match(new RegExp(`(?:${priceTypeRegex})`, 'g'))[0] || "Not Found",
            });
        }
    }

    // Fallback to unstructured matches if no structured prices
    if (prices.length === 0) {
        while ((match = unstructuredRegex.exec(inputString)) !== null) {
            const detectedNumber = match[0].replace(/[^\d.]/g, '');
            const validPrice = parseFloat(detectedNumber);
            if (validPrice < 1000) { // Ignore numbers larger than 999
                prices.push({ price: validPrice, type: "Unstructured" });
            }
        }
    }

    // If no prices found
    if (prices.length === 0) {
        return {
            price: '?',
            type: 'N/A',
        };
    }

    // Prioritize by type
    return prices.sort((a, b) => {
        const priority = { SB: 1, BIN: 3, FP: 4, "Starting Bid": 2, Unstructured: 5 };
        return (priority[a.type] || 99) - (priority[b.type] || 99);
    })[0]; // Return the top candidate
}

export const findRemove = (inputString) => {
    const pattern = /\bremove\b/i;
    return pattern.test(inputString);
}

export const findSold = (inputString) => {
    const pattern = /\bsold\b|\btraded\b(?!\s+notsold\b)/i;
    return pattern.test(inputString);
};